import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'

import MyPage from '../components/my_page'

const KainaPage = () => {
  const pageTitle = 'Kaina'
  const pageContent = (
    <span>
      <Table hover className="table-sm table-small-padding">
        <thead>
          <tr>
            <th>Paslaugų kainos:</th>
            <th>Įprasta kaina</th>
            <th>Kaina su 100€ kompensacija</th>
          </tr>
        </thead>
        <tbody>
          <tr className="table-light">
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Mėnesio ugdymo mokestis (maitinimas neįskaičiuotas) </td>
            <td>420€</td>
            <td>320€</td>
          </tr>
          <tr className="table-light">
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Stojimo į darželį mokestis (vienkartinis ir negrąžinamas)</td>
            <td>149€</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Pilnos dienos maitinimas (jei vaikas darželio nelanko, mokestis už
              maitinimą neskaičiuojamas)
            </td>
            <td>6.50€</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Vieno mėnesio papildomas mokestis už maitinimą pagal individualų
              valgiaraštį (esant tam tikrų produktų alergijoms/ netoleravimui ir
              kt., pateikus gydytojo pažymą)
            </td>
            <td>30€</td>
            <td></td>
          </tr>
          <tr>
            <td>Vaiko DRAUDIMAS nuo nelaimingų atsitikimų</td>
            <td>0€</td>
            <td></td>
          </tr>
          <tr className="table-light">
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td colSpan="3">
              Jei darželį lanko keli vienos šeimos vaikai bei giminaičių vaikai,
              antram ir kitiems vaikams taikoma nuolaida:
              <ol>
                <li>
                  {' '}
                  Antram vaikui (ir kitiems) nuo Tėvų mokamos ugdymo mokesčio
                  dalies taikoma 10% nuolaida.
                </li>
                <li>
                  {' '}
                  Antram vaikui (ir kitiems) taikoma 100 Eur nuolaida stojimo
                  mokesčiui (tėveliai moka tik 49 Eur).{' '}
                </li>
                <li>
                  {' '}
                  Antram vaikui (ir kitiems) suteikiame galimybę lankyti visus
                  būrelius, pagal amžiaus raidą, NEMOKAMAI (įprasta vieno
                  būrelio kaina mėnesiui yra 15 Eur).
                </li>
              </ol>
            </td>
          </tr>
          <tr className="table-light">
            <td> </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </Table>
      <p>
        Vilniaus miesto gyventojams taikoma 100 € kompensacija mėnesio ugdymo
        mokesčiui.
      </p>
      <p>
        Jei vaikas serga ilgiau kaip dvi savaites (10 darbo dienų) iš eilės per
        kalendorinį mėnesį, ugdymo mokestis mažinamas 10%.
      </p>

      <p>Vaikai priimami į darželį nuo 1 metukų.</p>

      <p>
        Galutinį sprendimą dėl ugdymo sutarties sudarymo priima ugdymo įstaiga,
        pasilikdama sau teisę dėl subjektyvių priežasčių sutarties nesudaryti
        (pvz., nepakankamas vaiko brandumas lankyti darželį, vertybiniai
        nesutapimai tarp tėvų ir darželio bei kitos panašios aplinkybės). Ugdymo
        įstaiga šių priežasčių neįsipareigoja komentuoti.
      </p>
    </span>
  )

  return (
    <MyPage pageType="simple" pageTitle={pageTitle} pageContent={pageContent} />
  )
}

export default KainaPage
